html {
  box-sizing: border-box;
  font-size: 62.5%;
}

* {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  font-size: 1.6rem;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: antiquewhite;
}