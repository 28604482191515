.page__user-list {
  .user-list__users {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    ul {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      padding: 1rem 2rem;
      border: 1px solid black;
      border-radius: 2rem;
      cursor: pointer;

      &.active {
        border: 2px solid red;
      }

      &.disabled {
        background-color: #ccc;
        opacity: 0.5;
      }

      &.pending {
        background-color: lightgreen;
      }
    }
  }

  & form.user-list__invite-form {
    width: min-content;

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    row-gap: 1rem;

    & input {
      min-width: 50rem;
    }

  }

}