.component__roster-detail {
  width: 100%;
  height: 100%;


  & .roster-detail__content {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    & .roster-detail__content__info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

}