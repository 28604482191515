.component__request-form {
  & .reqeust-form__selected-shifts {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & table.reqeust-form__selected-shifts__table {
      border-collapse: collapse;
      border: 1px solid #ccc;

      & .thead-row td {
        border-bottom: 1px solid black;
      }

      & td {
        padding: 0.5rem 1rem;
        border-right: 1px solid #ccc;

      }
    }
  }

}

