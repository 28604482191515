.flex--v {
  display: flex;
  flex-direction: column;
}

.flex--h {
  display: flex;
  flex-direction: row;
}

.flex-gap--d {
  gap: 1rem;
}

.flex-gap--md {
  gap: 2rem;
}

.flex-gap--lg {
  gap: 4rem;
}

.flex-align-cc {
  align-items: center;
  justify-content: center;
}

@import "layout";
@import "roster/calendar";
@import "roster/detail";
@import "requestForm";
@import "userList";

.btn {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.App {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;

  border: 1px solid black;

  & .please-sign-in {
    text-align: center;
  }

  & .navbar-collapse {
    width: 100%;

    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items: center;

    padding: 1rem 4rem;

    & .nav__sign-buttons {
      justify-self: flex-end;
    }

    .home__menu {
      & a {
        color: black;
        font-weight: bold;
        text-transform: capitalize;
        text-decoration: none;
        border-bottom: 1px solid black;
        padding: 1rem 0rem;
      }
    }
  }

  & .profileContent {
    width: 100%;
    padding: 0rem 4rem 4rem;
  }
}

.btn--primary {
  @extend .btn;
  color: white;

  border: 1px solid black;
  padding: 1rem 3rem;
  border-radius: 100rem;
  background-color: royalblue;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1.5rem;
  border: 1px solid black;
  border-radius: 2rem;

  background-color: white;

  &.card--user {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;

    & .card--user__profile-picture {
      width: 5rem;
      aspect-ratio: 1;
      background-image: url("../images/blank-profile.jpg");;
      background-size: contain;
      background-color: white;
      border-radius: 1000px;
    }
  }
}

.page__roster {
  gap: 2rem;
}

input {
  border-radius: 2rem;
  padding: 1rem 2rem;
  border: 1px solid #666;
}

select {
  padding: 1rem;
  border-radius: 2rem;
}

