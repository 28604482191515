div.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  //grid-template-rows: auto repeat(7, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;

  & .calendar__day {
    display: flex;
    flex-direction: column;

    & .calendar__day__boxes {
      height: 100%;
      display: grid;
      //grid-template-columns: 50% 50%;
      grid-template-rows: 1fr 1fr;

      border: 1px solid black;
      border-radius: 2rem;
      overflow: hidden;


      & div.calendar__day__box {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        padding: 1.5rem 1rem;

        cursor: pointer;

        box-sizing: border-box;
        background-color: white;

        &:nth-child(2n) {
          border-top: 1px solid black;
        }

        &.disabled {
          background-color: #ccc;
          opacity: 0.5;
        }

        &.approved {
          background-color: lightblue;
        }

        &.selected {
          background-color: lightgreen;
        }

        &.pending {
          background-color: lightcoral;
        }

        &.detail {
          border: 2px solid black;
        }


        & .calendar__day__boxes__header {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 1rem;

          .calendar__day__boxes__header__title {
            font-weight: 900;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid black;
          }


          .radio-selector {
            width: 2.5rem;
            aspect-ratio: 1;

            display: flex;
            align-items: center;
            justify-content: center;
            justify-self: flex-end;

            border: 1px solid black;
            border-radius: 1000px;
            background-color: white;

            overflow: hidden;

            //&::before {
            //  content: '';
            //  display: flex;
            //  width: 80%;
            //  aspect-ratio: 1;
            //  border-radius: 1000px;
            //}

            &.selected {
              border: 2px solid blue;
            }

            &.disabled {
              background-color: transparent;
            }
          }
        }

        & .calendar__day__box__info {
          & .calendar__day__box__requests {

            & .box__request {
              display: flex;
              align-items: center;
              border: 1px solid black;
              padding: 0.5rem 1rem;
              border-radius: 1rem;

              & p {
                width: 100%;
              }

              & span {
                padding: 0.5rem;
                text-decoration: underline;
              }

              &.approved {
                background-color: lightblue;
              }

              &.pending {
                background-color: lightcoral;
              }

            }
          }
        }


      }
    }
  }
}