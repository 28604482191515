.layout--two-column-detail {
  .body {
    position: relative;

    width: 100%;

    display: grid;
    grid-template-columns: 4fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
  }
}

.component {
  min-width: 30%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  border: 1px solid black;
  border-radius: 2rem;

  padding: 2rem;
  background-color: white;

  & h2 {
    width: fit-content;
    font-size: 2rem;
  }
}

form.two-column {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  align-items: center;
}